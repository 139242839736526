.element {
  background-color: #122131;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  user-select: none;
}

.element .div-2 {
  background-color: var(--variable-collection-bg);
  overflow: hidden;
  position: relative;
}

.element .overlap {
  position: absolute;
}

.element .vector {
  height: 991px;
  left: 875px;
  position: absolute;
  top: 171px;
  width: 355px;
}

.element .main {
  left: 0;
  position: absolute;
}

.element .overlap-2 {
  position: relative;
}

.element .overlap-3 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .pattern {
  position: absolute;
  top: 0;
}

.element .union-2 {
  left: 0;
  position: absolute;
}

.element .frame-13 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  position: absolute;
  top: 266px;
  width: 450px;
}

.element .frame-14 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
  width: 100%;
}

.element .star-2 {
  height: 18px;
  position: relative;
  width: 18px;
}

.element .text-wrapper-14 {
  color: #ffffff;
  flex: 1;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-top: -1px;
  position: relative;
}

.element .photo {
  height: 421px;
  left: 561px;
  position: absolute;
  top: 190px;
  width: 327px;
}

.element .overlap-4 {
  height: 421px;
  position: relative;
}

.element .group {
  background-image: url(../../../static/img/img-4.svg);
  background-size: 100% 100%;
  height: 413px;
  left: 4px;
  position: absolute;
  top: 5px;
  width: 320px;
}

.element .arc {
  height: 421px;
  left: 0;
  position: absolute;
  top: 0;
  width: 327px;
}

.element .overlap-group-3 {
  height: 419px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 325px;
}

.element .rectangle-4 {
  height: 412px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 163px;
}

.element .line-6 {
  height: 1px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 416px;
  width: 320px;
}

.element .star-3 {
  height: 8px;
  left: 0;
  position: absolute;
  top: 411px;
  width: 8px;
}

.element .rectangle-5 {
  height: 412px;
  left: 166px;
  position: absolute;
  top: 3px;
  width: 158px;
}

.element .star-4 {
  height: 8px;
  left: 317px;
  position: absolute;
  top: 411px;
  width: 8px;
}

.element .star-5 {
  height: 8px;
  left: 162px;
  position: absolute;
  top: 0;
  width: 8px;
}

.element .text-wrapper-15 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  white-space: nowrap;
}

.element .overlap-wrapper {
  height: 371px;
  left: 424px;
  position: absolute;
  top: 94px;
  width: 288px;
}

.element .overlap-5 {
  height: 371px;
  position: relative;
}

.element .group-2 {
  background-image: url(../../../static/img/img-6.svg);
  background-size: 100% 100%;
  height: 364px;
  left: 3px;
  position: absolute;
  top: 4px;
  width: 282px;
}

.element .overlap-group-wrapper {
  height: 371px;
  left: 0;
  position: absolute;
  top: 0;
  width: 288px;
}

.element .overlap-group-4 {
  height: 370px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 287px;
}

.element .rectangle-6 {
  height: 363px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 144px;
}

.element .line-7 {
  height: 1px;
  left: 3px;
  object-fit: cover;
  position: absolute;
  top: 366px;
  width: 282px;
}

.element .star-6 {
  height: 7px;
  left: 0;
  position: absolute;
  top: 363px;
  width: 7px;
}

.element .rectangle-7 {
  height: 363px;
  left: 146px;
  position: absolute;
  top: 3px;
  width: 139px;
}

.element .star-7 {
  height: 7px;
  left: 280px;
  position: absolute;
  top: 363px;
  width: 7px;
}

.element .star-8 {
  height: 7px;
  left: 143px;
  position: absolute;
  top: 0;
  width: 7px;
}

.element .frame-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  position: absolute;
  top: 148px;
  width: 372px;
}

.element .frame-16 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20px;
  position: relative;
}

.element .star-9 {
  height: 14px;
  position: relative;
  width: 14px;
}

.element .text-wrapper-16 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .p {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-right: -3.42px;
  margin-top: -1px;
  position: relative;
  width: 341px;
}

.element .button {
  left: 0;
  position: absolute;
}

.element .button:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .polygon {
  height: 68px;
  left: 39px;
  position: absolute;
  top: 46px;
  width: 78px;
}

.element .polygon-2 {
  height: 138px;
  left: 0;
  position: absolute;
  top: 46px;
  width: 156px;
}

.element .vector-2 {
  height: 90px;
  left: 77px;
  position: absolute;
  top: 2px;
  width: 1px;
}

.element .vector-3 {
  height: 46px;
  left: 78px;
  position: absolute;
  top: 91px;
  width: 77px;
}

.element .vector-4 {
  height: 46px;
  left: 1px;
  position: absolute;
  top: 91px;
  width: 77px;
}

.element .union-3 {
  left: 0;
  position: absolute;
}

.element .main-2 {
  position: absolute;
}

.element .text-wrapper-17 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  white-space: nowrap;
}

.element .frame-17 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
}

.element .text-wrapper-18 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-19 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .div-3 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-20 {
  color: #ffffff;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-21 {
  color: #ffffff;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-22 {
  color: #ffffff;
  font-family: var(--1280-h2-medium-font-family);
  font-size: var(--1280-h2-medium-font-size);
  font-style: var(--1280-h2-medium-font-style);
  font-weight: var(--1280-h2-medium-font-weight);
  left: 0;
  letter-spacing: var(--1280-h2-medium-letter-spacing);
  line-height: var(--1280-h2-medium-line-height);
  position: absolute;
  top: 0;
}

.element .button-2 {
  height: 68px;
  left: 600px;
  position: absolute;
  top: 1145px;
  width: 400px;
}

.element .button-2:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .text-wrapper-23 {
  color: var(--white);
  font-family: var(--1280-quotes-font-family);
  font-size: var(--1280-quotes-font-size);
  font-style: var(--1280-quotes-font-style);
  font-weight: var(--1280-quotes-font-weight);
  left: 100px;
  letter-spacing: var(--1280-quotes-letter-spacing);
  line-height: var(--1280-quotes-line-height);
  position: absolute;
  top: 963px;
  width: 580px;
}

.element .photo-2 {
  position: absolute;
}

.element .overlap-6 {
  position: relative;
}

.element .group-3 {
  background-size: 100% 100%;
  position: absolute;
}

.element .div-wrapper {
  left: 0;
  position: absolute;
  top: 0;
}

.element .overlap-group-5 {
  left: 1px;
  position: relative;
  top: 1px;
}

.element .rectangle-8 {
  position: absolute;
}

.element .line-8 {
  height: 1px;
  object-fit: cover;
  position: absolute;
}

.element .star-10 {
  left: 0;
  position: absolute;
}

.element .rectangle-9 {
  position: absolute;
}

.element .star-11 {
  position: absolute;
}

.element .star-12 {
  position: absolute;
  top: 0;
}

.element .union-4 {
  height: 32px;
  left: 24px;
  position: absolute;
  top: 0;
  width: 20px;
}

.element .union-5 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}

.element .view {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 498px;
  padding: 40px;
  position: absolute;
  top: 655px;
  width: 480px;
}

.element .frame-18 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 103px;
  position: relative;
  width: 400px;
}

.element .text-wrapper-24 {
  color: var(--white);
  font-family: var(--1280-h5-font-family);
  font-size: var(--1280-h5-font-size);
  font-style: var(--1280-h5-font-style);
  font-weight: var(--1280-h5-font-weight);
  letter-spacing: var(--1280-h5-letter-spacing);
  line-height: var(--1280-h5-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .star-13 {
  height: 22.81px;
  position: relative;
  width: 23.4px;
}

.element .text-wrapper-25 {
  color: #ffffff80;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  position: relative;
  width: 400px;
}

.element .view-2 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  padding: 40px;
  position: absolute;
  top: 160px;
  width: 480px;
}

.element .frame-19 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 30px;
  position: relative;
  width: 100%;
}

.element .div-4 {
  align-self: stretch;
  color: #ffffff80;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  position: relative;
}

.element .view-3 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 100px;
  padding: 40px;
  position: absolute;
  top: 468px;
  width: 380px;
}

.element .frame-20 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 73px;
  position: relative;
  width: 100%;
}

.element .text-wrapper-26 {
  color: var(--white);
  font-family: var(--1280-h5-font-family);
  font-size: var(--1280-h5-font-size);
  font-style: var(--1280-h5-font-style);
  font-weight: var(--1280-h5-font-weight);
  letter-spacing: var(--1280-h5-letter-spacing);
  line-height: var(--1280-h5-line-height);
  margin-top: -1px;
  position: relative;
  width: 203px;
}

.element .view-4 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 500px;
  padding: 40px;
  position: absolute;
  top: 303px;
  width: 367px;
}

.element .frame-21 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 65px;
  position: relative;
  width: 100%;
}

.element .text-wrapper-27 {
  color: var(--white);
  font-family: var(--1280-h5-font-family);
  font-size: var(--1280-h5-font-size);
  font-style: var(--1280-h5-font-style);
  font-weight: var(--1280-h5-font-weight);
  letter-spacing: var(--1280-h5-letter-spacing);
  line-height: var(--1280-h5-line-height);
  margin-top: -1px;
  position: relative;
  width: 198px;
}

.element .text-wrapper-28 {
  color: #ffffff80;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  position: relative;
  width: 287px;
}

.element .button-3 {
  position: absolute;
}

.element .button-3:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);
}

.element .pattern-2 {
  background-image: url(../../../static/img/union-9.svg);
  background-size: 100% 100%;
  height: 187px;
  left: 900px;
  position: absolute;
  top: 0;
  width: 220px;
}

.element .menu-icon-instance {
  left: 14px !important;
  top: 28px !important;
  width: 30px !important;
}

.element .class {
  height: 57px !important;
  left: 383px !important;
  position: absolute !important;
  top: 52px !important;
  width: 57px !important;
}

.element .class-2 {
  height: 57px !important;
  left: 280px !important;
  position: absolute !important;
  top: 28px !important;
  width: 57px !important;
}

.element .instance-node {
  left: 14px !important;
  top: 37px !important;
  width: 30px !important;
}

.element .menu-icon-2 {
  left: 14px !important;
  top: 20px !important;
  width: 30px !important;
}

.element .class-3 {
  left: 853px !important;
  position: absolute !important;
  top: 114px !important;
}

.element .class-4 {
  left: 661px !important;
  position: absolute !important;
  top: 55px !important;
}

.element .overlap-7 {
  position: absolute;
}

.element .golden-ratio-circles {
  position: absolute;
}

.element .overlap-8 {
  position: relative;
}

.element .rectangle-10 {
  position: absolute;
  top: 0;
  transform: rotate(-90deg);
}

.element .rectangle-11 {
  position: absolute;
  transform: rotate(-90deg);
}

.element .rectangle-12 {
  border: 1px solid;
  border-color: var(--variable-collection-white-50);
  position: absolute;
}

.element .rectangle-13 {
  border: 1px solid;
  border-color: var(--variable-collection-white-50);
  position: absolute;
}

.element .rectangle-14 {
  position: absolute;
  transform: rotate(-90deg);
}

.element .rectangle-15 {
  position: absolute;
  transform: rotate(-90deg);
}

.element .rectangle-16 {
  position: absolute;
  transform: rotate(-90deg);
}

.element .union-6 {
  position: absolute;
  transform: rotate(-90deg);
}

.element .factoids {
  position: absolute;
  top: 0;
}

.element .frame-22 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .component {
  height: 120px !important;
  position: relative !important;
  width: 210px !important;
}

.element .factoid-instance {
  height: 120px !important;
}

.element .component-2 {
  font-family: "DM Sans", Helvetica !important;
  font-size: 15px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  left: 144px !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  top: 75px !important;
}

.element .component-3 {
  height: 120px !important;
  width: 210px !important;
}

.element .component-4 {
  font-family: "Lora", Helvetica !important;
  font-size: 32px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  left: 82px !important;
  letter-spacing: 0 !important;
  line-height: 38.4px !important;
}

.element .component-5 {
  font-family: "DM Sans", Helvetica !important;
  font-size: 15px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  left: 88px !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  top: 75px !important;
}

.element .component-6 {
  font-family: "Lora", Helvetica !important;
  font-size: 32px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  left: 95px !important;
  letter-spacing: 0 !important;
  line-height: 38.4px !important;
}

.element .component-7 {
  height: 120px !important;
  position: relative !important;
  width: 208px !important;
}

.element .component-8 {
  font-family: "DM Sans", Helvetica !important;
  font-size: 15px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  left: 20px !important;
  letter-spacing: 0 !important;
  line-height: 18px !important;
  top: 75px !important;
}

.element .component-9 {
  height: 120px !important;
  width: 208px !important;
}

.element .component-10 {
  font-family: "Lora", Helvetica !important;
  font-size: 32px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  left: 113px !important;
  letter-spacing: 0 !important;
  line-height: 38.4px !important;
}

.element .frame-23 {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 20px 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 320px;
}

.element .component-11 {
  height: 84px;
  position: relative;
  width: 150px;
}

.element .overlap-group-6 {
  border-radius: 4px;
  height: 84px;
  position: relative;
}

.element .text-wrapper-29 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 91px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: right;
  top: 49px;
  white-space: nowrap;
}

.element .rectangle-17 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 4px;
  height: 84px;
  left: 0;
  position: absolute;
  top: 0;
  width: 150px;
}

.element .star-14 {
  height: 20px;
  left: 16px;
  position: absolute;
  top: 16px;
  width: 20px;
}

.element .text-wrapper-30 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 53px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
}

.element .text-wrapper-31 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 38px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: right;
  top: 49px;
  white-space: nowrap;
}

.element .star-15 {
  height: 20px;
  left: 20px;
  position: absolute;
  top: 16px;
  width: 20px;
}

.element .text-wrapper-32 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 63px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
}

.element .component-12 {
  height: 92px;
  position: relative;
  width: 150px;
}

.element .overlap-9 {
  border-radius: 4px;
  height: 92px;
  position: relative;
}

.element .text-wrapper-33 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  left: 20px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: right;
  top: 47px;
}

.element .rectangle-18 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 4px;
  height: 92px;
  left: 0;
  position: absolute;
  top: 0;
  width: 150px;
}

.element .text-wrapper-34 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 78px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  text-align: right;
  top: 15px;
  white-space: nowrap;
}

.element .group-wrapper {
  height: 123px;
  left: 191px;
  position: absolute;
  top: 119px;
  width: 42px;
}

.element .group-4 {
  height: 123px;
  position: relative;
}

.element .ellipse {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 3.67px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: -1px;
  width: 7px;
}

.element .subtract {
  height: 43px;
  left: -34px;
  position: absolute;
  top: 52px;
  width: 37px;
}

.element .ellipse-2 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 2.91px;
  height: 6px;
  left: 18px;
  position: absolute;
  top: 15px;
  width: 6px;
}

.element .ellipse-3 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 2.91px;
  height: 6px;
  left: 18px;
  position: absolute;
  top: 25px;
  width: 6px;
}

.element .overlap-group-7 {
  height: 85px;
  left: 9px;
  position: absolute;
  top: 39px;
  width: 23px;
}

.element .rectangle-19 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 85px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 8px;
}

.element .rectangle-20 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 8px;
  left: 6px;
  position: absolute;
  top: 7px;
  width: 11px;
}

.element .ellipse-4 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 3.29px;
  height: 7px;
  left: 0;
  position: absolute;
  top: 8px;
  width: 7px;
}

.element .ellipse-5 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 3.29px;
  height: 7px;
  left: 17px;
  position: absolute;
  top: 8px;
  width: 7px;
}

.element .subtract-2 {
  height: 15px;
  left: 65px;
  position: absolute;
  top: 45px;
  width: 17px;
}

.element .overlap-10 {
  height: 394px;
  left: 0;
  position: absolute;
  top: 0;
  width: 920px;
}

.element .overlap-11 {
  height: 319px;
  left: 0;
  position: absolute;
  top: 0;
  width: 920px;
}

.element .frame-24 {
  align-items: center;
  display: flex;
  gap: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 920px;
}

.element .component-13 {
  flex: 1 !important;
  flex-grow: 1 !important;
  position: relative !important;
  width: unset !important;
}

.element .component-14 {
  width: 293px !important;
}

.element .component-15 {
  left: 218px !important;
}

.element .component-16 {
  font-family: "Lora", Helvetica !important;
  font-size: 48px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  left: 112px !important;
  letter-spacing: 0 !important;
  line-height: 57.6px !important;
}

.element .component-17 {
  left: 150px !important;
}

.element .component-18 {
  font-family: "Lora", Helvetica !important;
  font-size: 48px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  left: 131px !important;
  letter-spacing: 0 !important;
  line-height: 57.6px !important;
}

.element .component-19 {
  left: 71px !important;
}

.element .component-20 {
  font-family: "Lora", Helvetica !important;
  font-size: 48px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  left: 161px !important;
  letter-spacing: 0 !important;
  line-height: 57.6px !important;
}

.element .key-2 {
  height: 240px;
  left: 638px;
  position: absolute;
  top: 190px;
  width: 82px;
}

.element .group-5 {
  height: 240px;
  position: relative;
}

.element .ellipse-6 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 6.22px;
  height: 12px;
  left: 35px;
  position: absolute;
  top: -1px;
  width: 12px;
}

.element .subtract-3 {
  height: 83px;
  left: -65px;
  position: absolute;
  top: 103px;
  width: 69px;
}

.element .ellipse-7 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 4.73px;
  height: 9px;
  left: 36px;
  position: absolute;
  top: 30px;
  width: 9px;
}

.element .ellipse-8 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 4.73px;
  height: 9px;
  left: 36px;
  position: absolute;
  top: 50px;
  width: 9px;
}

.element .overlap-group-8 {
  height: 163px;
  left: 30px;
  position: absolute;
  top: 78px;
  width: 20px;
}

.element .rectangle-21 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 163px;
  left: 3px;
  position: absolute;
  top: 0;
  width: 14px;
}

.element .rectangle-22 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 14px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 20px;
}

.element .subtract-4 {
  height: 27px;
  left: 127px;
  position: absolute;
  top: 89px;
  width: 31px;
}

.element .ellipse-9 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 5.47px;
  height: 11px;
  left: 18px;
  position: absolute;
  top: 93px;
  width: 11px;
}

.element .ellipse-10 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 5.47px;
  height: 11px;
  left: 51px;
  position: absolute;
  top: 93px;
  width: 11px;
}

.element .quote {
  height: 215px;
  left: 1px;
  position: absolute;
  top: 179px;
  width: 649px;
}

.element .text-wrapper-35 {
  color: #ffffff;
  font-family: var(--1280-quotes-font-family);
  font-size: var(--1280-quotes-font-size);
  font-style: var(--1280-quotes-font-style);
  font-weight: var(--1280-quotes-font-weight);
  left: 64px;
  letter-spacing: var(--1280-quotes-letter-spacing);
  line-height: var(--1280-quotes-line-height);
  position: absolute;
  top: 27px;
  width: 479px;
}

.element .text-wrapper-36 {
  color: #ffffff;
  font-family: var(--1280-quotes-font-family);
  font-size: var(--1280-quotes-font-size);
  font-style: var(--1280-quotes-font-style);
  font-weight: var(--1280-quotes-font-weight);
  left: 164px;
  letter-spacing: var(--1280-quotes-letter-spacing);
  line-height: var(--1280-quotes-line-height);
  position: absolute;
  top: 131px;
  width: 481px;
}

.element .quote-2 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 44px;
}

.element .hierarchy-secondary-state-default {
  margin-left: -4.5px !important;
  margin-right: -4.5px !important;
}

.element .hierarchy-secondary-instance {
  left: 470px !important;
  position: absolute !important;
  top: 434px !important;
}

.element .frame-25 {
  align-items: center;
  display: flex;
  gap: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 728px;
}

.element .component-21 {
  flex: 1 !important;
  flex-grow: 1 !important;
  height: 120px !important;
  position: relative !important;
  width: unset !important;
}

.element .component-22 {
  height: 120px !important;
  width: 229px !important;
}

.element .component-23 {
  font-family: "DM Sans", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  left: 160px !important;
  letter-spacing: 0 !important;
  line-height: 19.2px !important;
  top: 75px !important;
}

.element .component-24 {
  font-family: "Lora", Helvetica !important;
  font-size: 36px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  left: 88px !important;
  letter-spacing: 0 !important;
  line-height: 43.2px !important;
}

.element .component-25 {
  font-family: "DM Sans", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  left: 100px !important;
  letter-spacing: 0 !important;
  line-height: 19.2px !important;
  top: 75px !important;
}

.element .component-26 {
  font-family: "Lora", Helvetica !important;
  font-size: 36px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  left: 102px !important;
  letter-spacing: 0 !important;
  line-height: 43.2px !important;
}

.element .component-27 {
  font-family: "DM Sans", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  left: 29px !important;
  letter-spacing: 0 !important;
  line-height: 19.2px !important;
  top: 75px !important;
}

.element .component-28 {
  font-family: "Lora", Helvetica !important;
  font-size: 36px !important;
  font-style: unset !important;
  font-weight: 500 !important;
  left: 125px !important;
  letter-spacing: 0 !important;
  line-height: 43.2px !important;
}

.element .class-5 {
  font-family: "Lora", Helvetica !important;
  font-size: 20px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
}

.element .class-6 {
  font-family: "Lora", Helvetica !important;
  font-size: 18px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 21.6px !important;
}

.element .class-7 {
  display: inline-flex !important;
  gap: 10px !important;
  justify-content: unset !important;
  left: 29px !important;
  position: absolute !important;
  top: 472px !important;
  width: unset !important;
}

.element .class-8 {
  left: 0 !important;
  padding: 16px 20px 16px 32px !important;
  position: absolute !important;
  top: 463px !important;
  width: 320px !important;
}

.element .class-9 {
  display: inline-flex !important;
  gap: 10px !important;
  justify-content: unset !important;
  left: 249px !important;
  position: absolute !important;
  top: 394px !important;
  width: unset !important;
}

.element .key-3 {
  height: 181px;
  left: 542px;
  position: absolute;
  top: 168px;
  width: 62px;
}

.element .group-6 {
  height: 181px;
  position: relative;
}

.element .ellipse-11 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 4.95px/4.93px;
  height: 10px;
  left: 26px;
  position: absolute;
  top: -1px;
  width: 10px;
}

.element .subtract-5 {
  height: 63px;
  left: -49px;
  position: absolute;
  top: 77px;
  width: 53px;
}

.element .ellipse-12 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 3.82px/3.81px;
  height: 8px;
  left: 27px;
  position: absolute;
  top: 23px;
  width: 8px;
}

.element .ellipse-13 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 3.82px/3.81px;
  height: 8px;
  left: 27px;
  position: absolute;
  top: 37px;
  width: 8px;
}

.element .overlap-group-9 {
  height: 123px;
  left: 23px;
  position: absolute;
  top: 59px;
  width: 16px;
}

.element .rectangle-23 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 123px;
  left: 2px;
  position: absolute;
  top: 0;
  width: 11px;
}

.element .rectangle-24 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 11px;
  left: 0;
  position: absolute;
  top: 10px;
  width: 16px;
}

.element .subtract-6 {
  height: 21px;
  left: 96px;
  position: absolute;
  top: 67px;
  width: 24px;
}

.element .ellipse-14 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 4.38px/4.37px;
  height: 9px;
  left: 14px;
  position: absolute;
  top: 70px;
  width: 9px;
}

.element .ellipse-15 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 4.38px/4.37px;
  height: 9px;
  left: 38px;
  position: absolute;
  top: 70px;
  width: 9px;
}

.element .quote-3 {
  position: absolute;
}

.element .text-wrapper-37 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  position: absolute;
}

.element .text-wrapper-38 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  position: absolute;
}

.element .quote-4 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .union-7 {
  position: absolute;
  top: 0;
}

.element .union-8 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .key-4 {
  height: 145px;
  left: 317px;
  position: absolute;
  top: 170px;
  width: 50px;
}

.element .group-7 {
  height: 145px;
  position: relative;
}

.element .ellipse-16 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 4.18px/4.15px;
  height: 8px;
  left: 21px;
  position: absolute;
  top: -1px;
  width: 8px;
}

.element .subtract-7 {
  height: 51px;
  left: -40px;
  position: absolute;
  top: 61px;
  width: 43px;
}

.element .ellipse-17 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 3.27px/3.25px;
  height: 7px;
  left: 22px;
  position: absolute;
  top: 18px;
  width: 7px;
}

.element .ellipse-18 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 3.27px/3.25px;
  height: 7px;
  left: 22px;
  position: absolute;
  top: 30px;
  width: 7px;
}

.element .overlap-group-10 {
  height: 99px;
  left: 11px;
  position: absolute;
  top: 47px;
  width: 27px;
}

.element .rectangle-25 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 99px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 9px;
}

.element .rectangle-26 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 9px;
  left: 7px;
  position: absolute;
  top: 8px;
  width: 13px;
}

.element .ellipse-19 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 3.73px/3.7px;
  height: 7px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 7px;
}

.element .ellipse-20 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 3.73px/3.7px;
  height: 7px;
  left: 20px;
  position: absolute;
  top: 9px;
  width: 7px;
}

.element .subtract-8 {
  height: 17px;
  left: 77px;
  position: absolute;
  top: 53px;
  width: 20px;
}

.element .view-5 {
  position: absolute;
}

.element .text-wrapper-39 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.element .player {
  background-color: #d9d9d9;
  border-radius: 4px;
  left: 0;
  position: absolute;
}

.element .play {
  background-color: #122131;
  position: relative;
}

.element .polygon-3 {
  position: absolute;
}

.element .vector-5 {
  position: absolute;
}

.element .vector-6 {
  position: absolute;
}

.element .view-6 {
  height: 636px;
  left: 150px;
  position: absolute;
  top: 632px;
  width: 982px;
}

.element .text-wrapper-40 {
  color: #ffffff;
  font-family: var(--1280-h3-small-font-family);
  font-size: var(--1280-h3-small-font-size);
  font-style: var(--1280-h3-small-font-style);
  font-weight: var(--1280-h3-small-font-weight);
  left: 0;
  letter-spacing: var(--1280-h3-small-letter-spacing);
  line-height: var(--1280-h3-small-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .play-wrapper {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 554px;
  left: 0;
  position: absolute;
  top: 82px;
  width: 980px;
}

.element .polygon-wrapper {
  background-color: #122131;
  border-radius: 74px;
  height: 148px;
  left: 416px;
  position: relative;
  top: 203px;
  width: 148px;
}

.element .polygon-4 {
  height: 52px;
  left: 57px;
  position: absolute;
  top: 47px;
  width: 46px;
}

.element .vector-7 {
  height: 44px;
  left: 806px;
  position: absolute;
  top: 20px;
  width: 81px;
}

.element .golden-ratio-circles-2 {
  height: 180px;
  position: absolute;
  top: 336px;
  width: 292px;
}

.element .overlap-12 {
  height: 180px;
  position: relative;
}

.element .rectangle-27 {
  height: 112px;
  left: 180px;
  position: absolute;
  top: 0;
  transform: rotate(-90deg);
  width: 112px;
}

.element .rectangle-28 {
  height: 44px;
  left: 180px;
  position: absolute;
  top: 137px;
  transform: rotate(-90deg);
  width: 43px;
}

.element .rectangle-29 {
  border: 1px solid;
  border-color: var(--variable-collection-white-50);
  height: 28px;
  left: 179px;
  position: absolute;
  top: 41px;
  width: 28px;
}

.element .rectangle-30 {
  border: 1px solid;
  border-color: var(--variable-collection-white-50);
  height: 18px;
  left: 206px;
  position: absolute;
  top: 52px;
  width: 18px;
}

.element .rectangle-31 {
  height: 11px;
  left: 213px;
  position: absolute;
  top: 127px;
  transform: rotate(-90deg);
  width: 11px;
}

.element .rectangle-32 {
  height: 7px;
  left: 206px;
  position: absolute;
  top: 131px;
  transform: rotate(-90deg);
  width: 7px;
}

.element .rectangle-33 {
  height: 5px;
  left: 203px;
  position: absolute;
  top: 130px;
  transform: rotate(-90deg);
  width: 11px;
}

.element .union-9 {
  height: 292px;
  left: 56px;
  position: absolute;
  top: -56px;
  transform: rotate(-90deg);
  width: 180px;
}

.element .factoids-2 {
  height: 532px;
  left: 50px;
  position: absolute;
  top: 0;
  width: 1180px;
}

.element .overlap-13 {
  height: 340px;
  left: 793px;
  position: absolute;
  top: 0;
  width: 387px;
}

.element .component-29 {
  left: 7px !important;
  position: absolute !important;
  top: 0 !important;
}

.element .component-30 {
  left: 158px !important;
}

.element .component-31 {
  left: 220px !important;
}

.element .key-5 {
  height: 289px;
  left: 3px;
  position: absolute;
  top: 153px;
  width: 99px;
}

.element .group-8 {
  height: 289px;
  position: relative;
}

.element .ellipse-21 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 7.3px/7.28px;
  height: 15px;
  left: 42px;
  position: absolute;
  top: -1px;
  width: 15px;
}

.element .subtract-9 {
  height: 99px;
  left: -78px;
  position: absolute;
  top: 124px;
  width: 83px;
}

.element .ellipse-22 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 5.5px/5.49px;
  height: 11px;
  left: 44px;
  position: absolute;
  top: 37px;
  width: 11px;
}

.element .ellipse-23 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 5.5px/5.49px;
  height: 11px;
  left: 44px;
  position: absolute;
  top: 60px;
  width: 11px;
}

.element .overlap-group-11 {
  height: 196px;
  left: 37px;
  position: absolute;
  top: 94px;
  width: 24px;
}

.element .rectangle-34 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 196px;
  left: 4px;
  position: absolute;
  top: 0;
  width: 16px;
}

.element .rectangle-35 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  height: 16px;
  left: 0;
  position: absolute;
  top: 16px;
  width: 24px;
}

.element .subtract-10 {
  height: 32px;
  left: 154px;
  position: absolute;
  top: 107px;
  width: 37px;
}

.element .ellipse-24 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 6.4px/6.39px;
  height: 13px;
  left: 22px;
  position: absolute;
  top: 112px;
  width: 13px;
}

.element .ellipse-25 {
  background-color: #ffffff;
  border: 1px solid;
  border-color: #122131;
  border-radius: 6.4px/6.39px;
  height: 13px;
  left: 62px;
  position: absolute;
  top: 112px;
  width: 13px;
}

.element .component-32 {
  left: 400px !important;
  position: absolute !important;
  top: 0 !important;
}

.element .component-33 {
  left: 237px !important;
}

.element .component-34 {
  left: 182px !important;
}

.element .component-35 {
  left: 0 !important;
  position: absolute !important;
  top: 0 !important;
}

.element .hierarchy-secondary-state-default-instance {
  left: 700px !important;
  position: absolute !important;
  top: 464px !important;
}

.element .quote-5 {
  height: 215px;
  left: 136px;
  position: absolute;
  top: 209px;
  width: 648px;
}

.element .text-wrapper-41 {
  color: #ffffff;
  font-family: var(--1280-quotes-font-family);
  font-size: var(--1280-quotes-font-size);
  font-style: var(--1280-quotes-font-style);
  font-weight: var(--1280-quotes-font-weight);
  left: 163px;
  letter-spacing: var(--1280-quotes-letter-spacing);
  line-height: var(--1280-quotes-line-height);
  position: absolute;
  top: 131px;
  width: 481px;
}

.element .view-7 {
  position: absolute;
}

.element .frame-26 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .text-wrapper-42 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .text {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 100%;
}

.element .text-wrapper-43 {
  align-self: stretch;
  color: #ffffff;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-44 {
  align-self: stretch;
  color: #ffffff;
  position: relative;
}

.element .line-9 {
  align-self: stretch;
  height: 1px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.element .view-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.element .text-wrapper-45 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .frame-27 {
  position: relative;
}

.element .frame-28 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .instance-node-2 {
  flex: 0 0 auto !important;
}

.element .bullet-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.element .frame-29 {
  margin-right: -120px !important;
}

.element .frame-30 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;
}

.element .frame-31 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.element .frame-32 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 362px;
}

.element .text-wrapper-46 {
  color: #ffffff;
  font-family: var(--1280-h4-font-family);
  font-size: var(--1280-h4-font-size);
  font-style: var(--1280-h4-font-style);
  font-weight: var(--1280-h4-font-weight);
  letter-spacing: var(--1280-h4-letter-spacing);
  line-height: var(--1280-h4-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-47 {
  color: #ffffffb2;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-right: -323px;
  position: relative;
  width: 685px;
}

.element .frame-33 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 203px;
}

.element .text-wrapper-48 {
  align-self: stretch;
  color: #ffffffb2;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.element .text-wrapper-49 {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21.6px;
  position: relative;
  text-align: right;
}

.element .line-10 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.5px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.element .button-wrapper {
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.element .button-4 {
  position: relative;
}

.element .button-4:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .overlap-14 {
  height: 1069px;
  left: 0;
  position: absolute;
  top: 267px;
  width: 2097px;
}

.element .pattern-3 {
  height: 920px;
  left: 1103px;
  position: absolute;
  top: 75px;
  transform: rotate(10.29deg);
  width: 920px;
}

.element .overlap-group-12 {
  height: 829px;
  left: -75px;
  position: relative;
  top: 58px;
  width: 319px;
}

.element .union-10 {
  height: 810px;
  left: 71px;
  position: absolute;
  top: 9px;
  transform: rotate(-10.29deg);
  width: 177px;
}

.element .scroll-up {
  -webkit-backdrop-filter: blur(14px) brightness(100%);
  backdrop-filter: blur(14px) brightness(100%);
  background-color: #ffffff1a;
  border: 1px solid;
  border-color: #ffffff33;
  border-radius: 50px;
  height: 100px;
  left: 135px;
  position: absolute;
  top: 565px;
  transform: rotate(-10.29deg);
  width: 100px;
}

.element .rectangle-36 {
  height: 21px;
  left: 28px;
  position: absolute;
  top: 37px;
  width: 40px;
}

.element .header-instance {
  background-color: #ffffff0d !important;
  border-color: var(--white) !important;
  border-top-style: solid !important;
  border-top-width: 1px !important;
  left: 0 !important;
  position: absolute !important;
  top: 815px !important;
}

.element .view-9 {
  height: 168px;
  left: 50px;
  position: absolute;
  top: 814px;
  width: 944px;
}

.element .view-10 {
  height: 60px;
  left: 0;
  position: absolute;
  top: 0;
  width: 753px;
}

.element .text-wrapper-50 {
  color: #ffffff;
  font-family: var(--1280-h2-medium-font-family);
  font-size: var(--1280-h2-medium-font-size);
  font-style: var(--1280-h2-medium-font-style);
  font-weight: var(--1280-h2-medium-font-weight);
  left: 0;
  letter-spacing: var(--1280-h2-medium-letter-spacing);
  line-height: var(--1280-h2-medium-line-height);
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .btns {
  align-items: center;
  display: inline-flex;
  gap: 20px;
  left: 100px;
  position: absolute;
  top: 100px;
}

.element .view-11 {
  height: 714px;
  left: 50px;
  position: absolute;
  top: 0;
  width: 982px;
}

.element .view-12 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 100px;
  position: absolute;
  top: 100px;
  width: 880px;
}

.element .frame-34 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 30px;
  position: relative;
  width: 100%;
}

.element .text-wrapper-51 {
  color: var(--white);
  font-family: var(--1280-h4-font-family);
  font-size: var(--1280-h4-font-size);
  font-style: var(--1280-h4-font-style);
  font-weight: var(--1280-h4-font-weight);
  letter-spacing: var(--1280-h4-letter-spacing);
  line-height: var(--1280-h4-line-height);
  margin-top: -1px;
  position: relative;
  width: 280px;
}

.element .flexcontainer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.element .span {
  color: #ffffff80;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
}

.element .line-11 {
  align-self: stretch;
  height: 1px;
  object-fit: cover;
  position: relative;
  width: 100%;
}

.element .flexcontainer-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.element .text-wrapper-52 {
  color: var(--white);
  font-family: var(--1280-h4-font-family);
  font-size: var(--1280-h4-font-size);
  font-style: var(--1280-h4-font-style);
  font-weight: var(--1280-h4-font-weight);
  letter-spacing: var(--1280-h4-letter-spacing);
  line-height: var(--1280-h4-line-height);
  margin-top: -1px;
  position: relative;
  width: 584px;
}

.element .view-13 {
  position: absolute;
}

.element .text-wrapper-53 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 0;
  width: 232px;
}

.element .player-2 {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 180px;
  left: 0;
  position: absolute;
  top: 64px;
  width: 318px;
}

.element .img-wrapper {
  background-color: #122131;
  border-radius: 25px;
  height: 50px;
  left: 134px;
  position: relative;
  top: 65px;
  width: 50px;
}

.element .polygon-5 {
  height: 17px;
  left: 19px;
  position: absolute;
  top: 16px;
  width: 15px;
}

.element .union-wrapper {
  height: 206px;
  left: 1044px;
  position: absolute;
  top: 29px;
  transform: rotate(-20.11deg);
  width: 206px;
}

.element .union-11 {
  height: 206px;
  left: 1px;
  position: absolute;
  top: -3px;
  transform: rotate(20.11deg);
  width: 186px;
}

.element .view-14 {
  left: 0;
  position: absolute;
}

.element .text-wrapper-54 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.element .button-5 {
  position: absolute;
}

.element .button-5:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .pattern-4 {
  height: 147px;
  position: absolute;
  width: 144px;
}

.element .overlap-group-13 {
  height: 147px;
  left: 10px;
  position: relative;
  width: 125px;
}

.element .polygon-6 {
  height: 110px;
  left: 0;
  position: absolute;
  top: 0;
  width: 125px;
}

.element .polygon-7 {
  height: 55px;
  left: 31px;
  position: absolute;
  top: 37px;
  width: 62px;
}

.element .polygon-8 {
  height: 110px;
  left: 0;
  position: absolute;
  top: 37px;
  width: 125px;
}

.element .vector-8 {
  height: 72px;
  left: 62px;
  position: absolute;
  top: 1px;
  width: 1px;
}

.element .vector-9 {
  height: 37px;
  left: 62px;
  position: absolute;
  top: 73px;
  width: 62px;
}

.element .vector-10 {
  height: 37px;
  left: 1px;
  position: absolute;
  top: 73px;
  width: 62px;
}

.element .text-wrapper-55 {
  color: var(--white);
  position: absolute;
}

.element .quote-6 {
  left: 0;
  position: absolute;
}

.element .union-12 {
  position: absolute;
  top: 0;
}

.element .union-13 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .view-15 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
}

.element .text-wrapper-56 {
  color: var(--white);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-57 {
  align-self: stretch;
  color: #ffffff80;
  position: relative;
}

.element .view-16 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  position: absolute;
}

.element .text-wrapper-58 {
  color: var(--white);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-59 {
  align-self: stretch;
  color: #ffffff80;
  position: relative;
}

.element .view-17 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
}

.element .frame-35 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.element .text-wrapper-60 {
  color: var(--white);
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-61 {
  align-self: stretch;
  color: #ffffff80;
  position: relative;
}

.element .view-18 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
}

.element .frame-36 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.element .text-wrapper-62 {
  color: var(--white);
  margin-top: -1px;
  position: relative;
  width: 198px;
}

.element .text-wrapper-63 {
  color: #ffffff80;
  position: relative;
}

.element .pattern-5 {
  background-size: 100% 100%;
  position: absolute;
}

.element .text-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 600px;
  position: absolute;
  top: 100px;
  width: 580px;
}

.element .text-wrapper-64 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--1280-h4-font-family);
  font-size: var(--1280-h4-font-size);
  font-style: var(--1280-h4-font-style);
  font-weight: var(--1280-h4-font-weight);
  letter-spacing: var(--1280-h4-letter-spacing);
  line-height: var(--1280-h4-line-height);
  margin-top: -1px;
  position: relative;
}

.element .frame-37 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 18px;
  position: relative;
  width: 100%;
}

.element .frame-38 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}

.element .text-wrapper-65 {
  color: #ffffff;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-top: -1px;
  position: relative;
  width: 546px;
}

.element .video {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 311px;
  left: 0;
  position: absolute;
  top: 100px;
  width: 550px;
}

.element .group-9 {
  background-color: var(--variable-collection-bg);
  border-radius: 36px;
  height: 72px;
  left: 239px;
  position: relative;
  top: 120px;
  width: 72px;
}

.element .polygon-9 {
  height: 24px;
  left: 28px;
  position: absolute;
  top: 24px;
  width: 22px;
}

.element .vector-11 {
  position: absolute;
}

.element .overlap-15 {
  position: absolute;
}

.element .view-19 {
  position: absolute;
}

.element .text-wrapper-66 {
  color: #ffffff;
  left: 0;
  position: absolute;
  top: 0;
}

.element .frame-39 {
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0) 48.59%, rgb(255, 255, 255) 100%) 1;
  border-radius: 4px;
  display: flex;
  left: 0;
  position: absolute;
  top: 0;
}

.element .IFS-EMDR {
  color: #ffffff;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .div-5 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 178px;
}

.element .img-2 {
  height: 40px;
  position: relative;
  width: 40px;
}

.element .text-wrapper-67 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .div-6 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 56px;
  position: absolute;
  top: 238px;
}

.element .overlap-16 {
  height: 40px;
  position: relative;
}

.element .rectangle-37 {
  height: 40px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.element .vector-12 {
  height: 19px;
  left: 5px;
  position: absolute;
  top: 7px;
  width: 19px;
}

.element .group-10 {
  height: 29px;
  left: 25px;
  position: absolute;
  top: -1px;
  transform: rotate(45.61deg);
  width: 5px;
}

.element .overlap-group-14 {
  height: 22px;
  left: -1px;
  position: absolute;
  top: 6px;
  width: 7px;
}

.element .vector-13 {
  height: 5px;
  left: 1px;
  position: absolute;
  top: 16px;
  transform: rotate(-45.61deg);
  width: 5px;
}

.element .rectangle-38 {
  border: 1.7px solid;
  border-color: var(--white);
  height: 17px;
  left: 1px;
  position: absolute;
  top: 0;
  transform: rotate(0.58deg);
  width: 5px;
}

.element .rectangle-39 {
  border: 1.7px solid;
  border-color: var(--white);
  border-radius: 2px 2px 0px 0px;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(0.58deg);
  width: 5px;
}

.element .vector-14 {
  height: 9px;
  left: 16px;
  position: absolute;
  top: 26px;
  width: 9px;
}

.element .div-7 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 112px;
  position: absolute;
  top: 297px;
}

.element .union-14 {
  height: 682px;
  left: 0;
  position: absolute;
  top: 436px;
  width: 1280px;
}

.element .vector-15 {
  height: 682px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 1280px;
}

.element .text-wrapper-68 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 50px;
  font-weight: 500;
  left: 150px;
  letter-spacing: 0;
  line-height: 60px;
  position: absolute;
  top: 0;
}

.element .tarif {
  position: absolute;
}

.element .overlap-17 {
  position: relative;
}

.element .button-6 {
  height: 68px;
  left: 42px;
  position: absolute;
  top: 575px;
  width: 412px;
  z-index: 100;
}

.element .button-6:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .content {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  left: 42px;
  position: absolute;
  top: 173px;
  width: 412px;
}

.element .text-wrapper-69 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--1280-h4-font-family);
  font-size: var(--1280-h4-font-size);
  font-style: var(--1280-h4-font-style);
  font-weight: var(--1280-h4-font-weight);
  letter-spacing: var(--1280-h4-letter-spacing);
  line-height: var(--1280-h4-line-height);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.element .frame-40 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.element .frame-41 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 100%;
}

.element .star-wrapper {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 6px 0px;
  position: relative;
}

.element .text-wrapper-70 {
  color: #ffffff80;
  flex: 1;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-top: -1px;
  position: relative;
}

.element .div-8 {
  align-self: stretch;
  color: var(--white);
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  position: relative;
}

.element .arc-2 {
  height: 686px;
  left: 0;
  position: absolute;
  top: 0;
  width: 497px;
}

.element .overlap-group-15 {
  height: 686px;
  position: relative;
}

.element .rectangle-40 {
  height: 682px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 247px;
}

.element .rectangle-41 {
  height: 682px;
  left: 248px;
  position: absolute;
  top: 3px;
  width: 247px;
}

.element .line-12 {
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 682px;
  width: 445px;
}

.element .ellipse-26 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 245px;
  position: absolute;
  top: 0;
  width: 6px;
}

.element .ellipse-27 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 0;
  position: absolute;
  top: 680px;
  width: 6px;
}

.element .ellipse-28 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 491px;
  position: absolute;
  top: 680px;
  width: 6px;
}

.element .eye {
  height: 63px;
  left: 177px;
  position: absolute;
  top: 80px;
  width: 128px;
}

.element .overlap-18 {
  height: 63px;
  position: relative;
}

.element .subtract-11 {
  height: 58px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 128px;
}

.element .subtract-12 {
  height: 59px;
  left: 31px;
  position: absolute;
  top: 0;
  width: 66px;
}

.element .rectangle-42 {
  height: 25px;
  left: 55px;
  position: absolute;
  top: 39px;
  width: 18px;
}

.element .subtract-13 {
  height: 21px;
  left: 53px;
  position: absolute;
  top: 21px;
  width: 21px;
}

.element .div-9 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  position: relative;
}

.element .img-3 {
  height: 50px;
  position: relative;
  width: 50px;
}

.element .text-wrapper-71 {
  color: var(--white);
  font-family: var(--1280-h5-font-family);
  font-size: var(--1280-h5-font-size);
  font-style: var(--1280-h5-font-style);
  font-weight: var(--1280-h5-font-weight);
  letter-spacing: var(--1280-h5-letter-spacing);
  line-height: var(--1280-h5-line-height);
  position: relative;
  width: 223px;
}

.element .line-13 {
  height: 7.36px;
  object-fit: cover;
  position: relative;
  width: 50.5px;
}

.element .frame-42 {
  height: 49px;
  position: relative;
  width: 50px;
}

.element .overlap-19 {
  height: 49px;
  position: relative;
}

.element .rectangle-43 {
  height: 49px;
  left: 0;
  position: absolute;
  top: 0;
  width: 40px;
}

.element .vector-16 {
  height: 23px;
  left: 6px;
  position: absolute;
  top: 9px;
  width: 23px;
}

.element .group-11 {
  height: 36px;
  left: 32px;
  position: absolute;
  top: -1px;
  transform: rotate(45.61deg);
  width: 6px;
}

.element .overlap-group-16 {
  height: 28px;
  left: -1px;
  position: absolute;
  top: 7px;
  width: 9px;
}

.element .vector-17 {
  height: 6px;
  left: 1px;
  position: absolute;
  top: 20px;
  transform: rotate(-45.61deg);
  width: 6px;
}

.element .rectangle-44 {
  border: 1.7px solid;
  border-color: var(--white);
  height: 21px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 6px;
}

.element .rectangle-45 {
  border: 1.7px solid;
  border-color: var(--white);
  border-radius: 2px 2px 0px 0px;
  height: 6px;
  left: 0;
  position: absolute;
  top: 0;
  width: 6px;
}

.element .vector-18 {
  height: 11px;
  left: 20px;
  position: absolute;
  top: 32px;
  width: 11px;
}

.element .text-wrapper-72 {
  color: var(--white);
  font-family: var(--1280-h5-font-family);
  font-size: var(--1280-h5-font-size);
  font-style: var(--1280-h5-font-style);
  font-weight: var(--1280-h5-font-weight);
  letter-spacing: var(--1280-h5-letter-spacing);
  line-height: var(--1280-h5-line-height);
  position: relative;
  width: 233px;
}

.element .tarif-2 {
  height: 686px;
  left: 650px;
  position: absolute;
  top: 72px;
  width: 497px;
}

.element .button-7 {
  height: 68px;
  left: 42px;
  position: absolute;
  z-index: 100;
  top: 577px;
  width: 412px;
}

.element .button-7:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .content-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  left: 42px;
  position: absolute;
  top: 153px;
  width: 412px;
}

.element .frame-43 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 0px 0px 0px 40px;
  position: relative;
  width: 100%;
}

.element .line-14 {
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 684px;
  width: 445px;
}

.element .eye-2 {
  height: 63px;
  left: 184px;
  position: absolute;
  top: 60px;
  width: 128px;
}

.element .group-12 {
  height: 82px;
  left: 186px;
  position: absolute;
  top: 960px;
  width: 519px;
}

.element .text-wrapper-73 {
  color: #ffffff;
  font-family: var(--1280-quotes-font-family);
  font-size: var(--1280-quotes-font-size);
  font-style: var(--1280-quotes-font-style);
  font-weight: var(--1280-quotes-font-weight);
  left: 64px;
  letter-spacing: var(--1280-quotes-letter-spacing);
  line-height: var(--1280-quotes-line-height);
  position: absolute;
  top: 27px;
  width: 453px;
}

.element .text-wrapper-74 {
  color: var(--variable-collection-white-50);
  font-family: "DM Sans", Helvetica;
  font-style: italic;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  position: absolute;
}

.element .vector-19 {
  height: 20px;
  left: 20px;
  position: absolute;
  top: 238px;
  width: 17px;
}

.element .vector-20 {
  height: 20px;
  left: 76px;
  position: absolute;
  top: 298px;
  width: 17px;
}

.element .view-20 {
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
}

.element .view-21 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
}

.element .text-wrapper-75 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .frame-44 {
  height: 32px;
  position: relative;
  width: 32px;
  z-index: 10000;
}

.element .flexcontainer-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.element .span-wrapper {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.element .span-2 {
  color: #ffffff;
}

.element .text-3 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.element .span-3 {
  color: #ffffff;
}

.element .text-4 {
  align-self: stretch;
  color: var(--white);
  position: relative;
}

.element .text-wrapper-76 {
  color: #ffffff;
}

.element .frame-45 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.element .frame-46 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.element .text-wrapper-77 {
  color: #ffffff;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-78 {
  align-self: stretch;
  color: #ffffffb2;
  position: relative;
}

.element .frame-47 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 203px;
}

.element .text-wrapper-79 {
  align-self: stretch;
  color: #ffffffb2;
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.element .clin-soc-work-j {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  text-align: right;
}

.element .class-10 {
  display: inline-flex !important;
  flex: 0 0 auto !important;
  gap: 10px !important;
  justify-content: unset !important;
  width: unset !important;
}

.element .view-22 {
  position: absolute;
}

.element .vector-21 {
  left: 0;
  position: absolute;
}

.element .vector-22 {
  height: 682px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 960px;
}

.element .text-wrapper-80 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 50px;
  font-weight: 500;
  left: 17px;
  letter-spacing: 0;
  line-height: 60px;
  position: absolute;
  top: 0;
}

.element .frame-48 {
  align-items: center;
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0) 48.59%, rgb(255, 255, 255) 100%) 1;
  border-radius: 4px;
  display: inline-flex;
  gap: 34px;
  left: 0;
  padding: 24px 34px 24px 40px;
  position: absolute;
  top: 0;
}

.element .text-wrapper-81 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 23px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 27.6px;
  margin-top: -1px;
  position: relative;
  width: 1048px;
}

.element .view-23 {
  position: absolute;
}

.element .div-10 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 0;
}

.element .text-wrapper-82 {
  color: var(--white);
  font-family: var(--1280-h5-font-family);
  font-size: var(--1280-h5-font-size);
  font-style: var(--1280-h5-font-style);
  font-weight: var(--1280-h5-font-weight);
  letter-spacing: var(--1280-h5-letter-spacing);
  line-height: var(--1280-h5-line-height);
  margin-top: -1px;
  position: relative;
  width: 223px;
}

.element .div-11 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 398px;
  position: absolute;
  top: 0;
}

.element .text-wrapper-83 {
  color: var(--white);
  font-family: var(--1280-h5-font-family);
  font-size: var(--1280-h5-font-size);
  font-style: var(--1280-h5-font-style);
  font-weight: var(--1280-h5-font-weight);
  letter-spacing: var(--1280-h5-letter-spacing);
  line-height: var(--1280-h5-line-height);
  margin-top: -1px;
  position: relative;
  width: 233px;
}

.element .div-12 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 798px;
  position: absolute;
  top: 0;
}

.element .line-15 {
  height: 7px;
  left: 298px;
  object-fit: cover;
  position: absolute;
  top: 24px;
  width: 80px;
}

.element .line-16 {
  height: 7px;
  left: 698px;
  object-fit: cover;
  position: absolute;
  top: 24px;
  width: 80px;
}

.element .overlap-20 {
  position: relative;
}

.element .button-8 {
  position: absolute;
  z-index: 10000;
}

.element .button-8:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .content-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: absolute;
}

.element .div-13 {
  align-self: stretch;
  color: var(--white);
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.element .frame-49 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.element .star-16 {
  position: relative;
}

.element .div-14 {
  color: #ffffff80;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.element .frame-50 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.element .star-17 {
  position: relative;
}

.element .div-15 {
  color: #ffffff80;
  flex: 1;
  margin-top: -1px;
  position: relative;
}

.element .frame-51 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 0px 0px 0px 24px;
  position: relative;
  width: 100%;
}

.element .frame-52 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

.element .star-18 {
  height: 12px;
  position: relative;
  width: 12px;
}

.element .text-wrapper-84 {
  color: #ffffff80;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
}

.element .div-16 {
  color: var(--white);
  position: relative;
}

.element .arc-3 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .overlap-group-17 {
  position: relative;
}

.element .rectangle-46 {
  left: 2px;
  position: absolute;
  top: 3px;
}

.element .rectangle-47 {
  position: absolute;
  top: 3px;
}

.element .line-17 {
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
}

.element .ellipse-29 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  position: absolute;
  top: 0;
  width: 6px;
}

.element .ellipse-30 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 0;
  position: absolute;
  width: 6px;
}

.element .ellipse-31 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  position: absolute;
  width: 6px;
}

.element .eye-3 {
  position: absolute;
}

.element .overlap-21 {
  position: relative;
}

.element .subtract-14 {
  left: 0;
  position: absolute;
  top: 2px;
}

.element .subtract-15 {
  position: absolute;
  top: 0;
}

.element .rectangle-48 {
  position: absolute;
}

.element .subtract-16 {
  position: absolute;
}

.element .text-wrapper-85 {
  color: var(--variable-collection-white-50);
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  top: 240px;
  width: 1180px;
}

.element .tarif-3 {
  position: absolute;
}

.element .button-9 {
  height: 68px;
  left: 21px;
  position: absolute;
  top: 577px;
  width: 412px;
  z-index: 10000;
}

.element .button-9:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .content-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 30px;
  left: 22px;
  position: absolute;
  top: 153px;
  width: 412px;
}

.element .arc-4 {
  height: 686px;
  left: 0;
  position: absolute;
  top: 0;
  width: 453px;
}

.element .rectangle-49 {
  height: 682px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 225px;
}

.element .rectangle-50 {
  height: 682px;
  left: 226px;
  position: absolute;
  top: 3px;
  width: 225px;
}

.element .ellipse-32 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 223px;
  position: absolute;
  top: 0;
  width: 6px;
}

.element .ellipse-33 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 447px;
  position: absolute;
  top: 680px;
  width: 6px;
}

.element .eye-4 {
  height: 63px;
  left: 162px;
  position: absolute;
  top: 60px;
  width: 128px;
}

.element .text-wrapper-86 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  left: 44px;
  letter-spacing: 0;
  line-height: 21.6px;
  position: absolute;
  top: 13px;
  width: 396px;
}

.element .quote-7 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.element .union-15 {
  height: 17px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 11px;
}

.element .union-16 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 11px;
}

.element .group-13 {
  height: 82px;
  left: 158px;
  position: absolute;
  top: 960px;
  width: 519px;
}

.element .text-wrapper-87 {
  color: #ffffff;
  font-family: var(--1280-h3-small-font-family);
  font-size: var(--1280-h3-small-font-size);
  font-style: var(--1280-h3-small-font-style);
  font-weight: var(--1280-h3-small-font-weight);
  letter-spacing: var(--1280-h3-small-letter-spacing);
  line-height: var(--1280-h3-small-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .flexcontainer-4 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.element .text-wrapper-88 {
  color: #ffffff;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
}

.element .overlap-22 {
  height: 704px;
  left: 23px;
  position: absolute;
  top: 44px;
  width: 480px;
}

.element .vector-23 {
  height: 278px;
  left: 0;
  position: absolute;
  top: 0;
  width: 480px;
}

.element .text-wrapper-89 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 17px;
  letter-spacing: 0;
  line-height: 38.4px;
  position: absolute;
  top: 5px;
}

.element .tarif-4 {
  height: 603px;
  left: 37px;
  position: absolute;
  top: 101px;
  width: 406px;
}

.element .overlap-group-18 {
  height: 603px;
  position: relative;
}

.element .button-10 {
  height: 64px;
  left: 44px;
  position: absolute;
  top: 493px;
  width: 318px;
  z-index: 10000;
}

.element .button-10:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .content-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 33px;
  position: absolute;
  top: 112px;
  width: 340px;
}

.element .text-wrapper-90 {
  align-self: stretch;
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.element .text-wrapper-91 {
  color: var(--white);
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  position: relative;
  width: 292px;
}

.element .arc-5 {
  height: 603px;
  left: 0;
  position: absolute;
  top: 0;
  width: 406px;
}

.element .rectangle-51 {
  height: 597px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 204px;
}

.element .rectangle-52 {
  height: 597px;
  left: 206px;
  position: absolute;
  top: 3px;
  width: 198px;
}

.element .line-18 {
  height: 1px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 599px;
  width: 396px;
}

.element .ellipse-34 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 200px;
  position: absolute;
  top: 0;
  width: 6px;
}

.element .ellipse-35 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 0;
  position: absolute;
  top: 597px;
  width: 6px;
}

.element .ellipse-36 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 400px;
  position: absolute;
  top: 597px;
  width: 6px;
}

.element .eye-5 {
  height: 39px;
  left: 163px;
  position: absolute;
  top: 43px;
  width: 80px;
}

.element .overlap-23 {
  height: 39px;
  position: relative;
}

.element .subtract-17 {
  height: 36px;
  left: 0;
  position: absolute;
  top: 2px;
  width: 80px;
}

.element .subtract-18 {
  height: 36px;
  left: 19px;
  position: absolute;
  top: 0;
  width: 41px;
}

.element .rectangle-53 {
  height: 15px;
  left: 34px;
  position: absolute;
  top: 24px;
  width: 11px;
}

.element .subtract-19 {
  height: 13px;
  left: 33px;
  position: absolute;
  top: 13px;
  width: 13px;
}

.element .view-25 {
  position: absolute;
}

.element .frame-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  position: absolute;
  top: 396px;
  width: 440px;
}

.element .frame-53 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

.element .text-wrapper-92 {
  color: #ffffff;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
}

.element .video-2 {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 250px;
  left: 0;
  position: absolute;
  top: 126px;
  width: 440px;
}

.element .group-14 {
  background-color: var(--variable-collection-bg);
  border-radius: 36px;
  height: 72px;
  left: 184px;
  position: relative;
  top: 89px;
  width: 72px;
}

.element .polygon-10 {
  height: 25px;
  left: 28px;
  position: absolute;
  top: 23px;
  width: 22px;
}

.element .text-wrapper-93 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 32px;
  font-weight: 500;
  left: 2px;
  letter-spacing: 0;
  line-height: 38.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .text-wrapper-94 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  left: 3px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 58px;
  width: 437px;
}

.element .union-17 {
  height: 179px;
  left: 3px;
  position: absolute;
  top: -15px;
  transform: rotate(20.11deg);
  width: 90px;
}

.element .pattern-6 {
  position: absolute;
}

.element .union-18 {
  height: 120px;
  left: 1px;
  position: absolute;
  top: -8px;
  transform: rotate(15deg);
  width: 61px;
}

.element .text-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  position: absolute;
  top: 715px;
  width: 918px;
}

.element .frame-54 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 17px;
  position: relative;
  width: 100%;
}

.element .video-3 {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 521px;
  left: 0;
  position: absolute;
  top: 164px;
  width: 918px;
}

.element .group-15 {
  background-color: var(--variable-collection-bg);
  border-radius: 40px;
  height: 80px;
  left: 419px;
  position: relative;
  top: 221px;
  width: 80px;
}

.element .polygon-11 {
  height: 32px;
  left: 30px;
  position: absolute;
  top: 24px;
  width: 28px;
}

.element .text-wrapper-95 {
  color: var(--white);
  font-family: var(--1280-h4-font-family);
  font-size: var(--1280-h4-font-size);
  font-style: var(--1280-h4-font-style);
  font-weight: var(--1280-h4-font-weight);
  left: 1px;
  letter-spacing: var(--1280-h4-letter-spacing);
  line-height: var(--1280-h4-line-height);
  position: absolute;
  top: 100px;
  white-space: nowrap;
}

.element .view-26 {
  position: absolute;
}

.element .text-wrapper-96 {
  color: #ffffff;
  left: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .view-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
}

.element .frame-55 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .text-wrapper-97 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: 236px;
}

.element .btn {
  all: unset;
  align-items: center;
  box-sizing: border-box;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
}

.element .frame-56 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 4px 10px;
  position: relative;
}

.element .text-wrapper-98 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .frame-57 {
  height: 24px;
  position: relative;
  width: 32px;
}

.element .flexcontainer-5 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.element .text-6 {
  align-self: stretch;
  color: #ffffff80;
  position: relative;
}

.element .text-wrapper-99 {
  color: #ffffff80;
}

.element .text-7 {
  align-self: stretch;
  color: #ffffff80;
  position: relative;
}

.element .text-wrapper-100 {
  color: #ffffff80;
}

.element .frame-58 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .flexcontainer-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.element .text-8 {
  align-self: stretch;
  color: #ffffff80;
  position: relative;
}

.element .text-wrapper-101 {
  color: #ffffff80;
}

.element .text-9 {
  align-self: stretch;
  color: #ffffff80;
  position: relative;
}

.element .text-wrapper-102 {
  color: #ffffff80;
}

.element .frame-59 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .text-wrapper-103 {
  align-self: stretch;
  color: #ffffff80;
  position: relative;
}

.element .text-wrapper-104 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--1280-h2-medium-font-family);
  font-size: var(--1280-h2-medium-font-size);
  font-style: var(--1280-h2-medium-font-style);
  font-weight: var(--1280-h2-medium-font-weight);
  letter-spacing: var(--1280-h2-medium-letter-spacing);
  line-height: var(--1280-h2-medium-line-height);
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-105 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-106 {
  align-self: stretch;
  color: #ffffff;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  position: relative;
}

.element .frame-60 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.element .button-11 {
  position: relative;
  width: 412px;
  z-index: 10000;
}

.element .button-11:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);
}

.element .view-28 {
  position: absolute;
}

.element .view-29 {
  position: absolute;
}

.element .overlap-24 {
  height: 669px;
  left: 600px;
  position: absolute;
  top: 0;
  width: 509px;
}

.element .pattern-7 {
  background-image: url(../../../static/img/union-11.svg);
  background-size: 100% 100%;
  height: 461px;
  left: 0;
  position: absolute;
  top: 0;
  width: 461px;
}

.element .photo-3 {
  height: 533px;
  left: 95px;
  position: absolute;
  top: 136px;
  width: 414px;
}

.element .overlap-25 {
  height: 533px;
  position: relative;
}

.element .group-16 {
  background-image: url(../../../static/img/img.svg);
  background-size: 100% 100%;
  height: 523px;
  left: 5px;
  position: absolute;
  top: 6px;
  width: 405px;
}

.element .arc-6 {
  height: 533px;
  left: 0;
  position: absolute;
  top: 0;
  width: 414px;
}

.element .overlap-group-19 {
  height: 531px;
  left: 1px;
  position: relative;
  top: 1px;
  width: 412px;
}

.element .rectangle-54 {
  height: 521px;
  left: 3px;
  position: absolute;
  top: 4px;
  width: 207px;
}

.element .line-19 {
  height: 1px;
  left: 4px;
  object-fit: cover;
  position: absolute;
  top: 527px;
  width: 405px;
}

.element .star-19 {
  height: 10px;
  left: 0;
  position: absolute;
  top: 521px;
  width: 10px;
}

.element .rectangle-55 {
  height: 521px;
  left: 210px;
  position: absolute;
  top: 4px;
  width: 200px;
}

.element .star-20 {
  height: 10px;
  left: 402px;
  position: absolute;
  top: 521px;
  width: 10px;
}

.element .star-21 {
  height: 10px;
  left: 205px;
  position: absolute;
  top: 0;
  width: 10px;
}

.element .text-wrapper-107 {
  color: #ffffff;
  font-family: var(--1280-h1-large-font-family);
  font-size: var(--1280-h1-large-font-size);
  font-style: var(--1280-h1-large-font-style);
  font-weight: var(--1280-h1-large-font-weight);
  left: 0;
  letter-spacing: var(--1280-h1-large-letter-spacing);
  line-height: var(--1280-h1-large-line-height);
  position: absolute;
  top: 142px;
  white-space: nowrap;
}

.element .frame-61 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 100px;
  position: absolute;
  top: 285px;
  width: 372px;
}

.element .text-wrapper-108 {
  color: #ffffff;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-109 {
  color: #ffffff;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-right: -7.42px;
  margin-top: -1px;
  position: relative;
  width: 341px;
}

.element .button-12 {
  height: 68px;
  left: 0;
  position: absolute;
  top: 596px;
  width: 412px;
  z-index: 10000;
}

.element .button-12:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .text-wrapper-110 {
  color: #ffffff;
  left: 0;
  position: absolute;
  top: 0;
}

.element .btns-2 {
  align-items: center;
  gap: 20px;
  left: 0;
  position: absolute;
}

.element .class-11 {
  flex: 0 0 auto !important;
  width: 390px !important;
}

/* .element .header-2 {
  background-color: #ffffff0d !important;
  left: 0 !important;
  position: sticky;
  top: 0;
  width: 1280px;
  z-index: 1000;
} */

.element .view-30 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 20px;
  position: absolute;
}

.element .frame-62 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .text-wrapper-111 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .text-10 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .div-17 {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-112 {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .text-wrapper-113 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .frame-63 {
  position: relative;
}

.element .frame-64 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .frame-65 {
  font-family: "Lora", Helvetica !important;
  font-size: 22px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 26.4px !important;
}

.element .instance-node-3 {
  font-family: "DM Sans", Helvetica !important;
  font-size: 16px !important;
  font-style: unset !important;
  font-weight: 400 !important;
  letter-spacing: 0 !important;
  line-height: 19.2px !important;
}

.element .frame-66 {
  font-size: 16px !important;
  line-height: 19.2px !important;
}

.element .frame-67 {
  font-family: "Lora", Helvetica !important;
  font-size: 22px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 26.4px !important;
  margin-right: -17px !important;
}

.element .text-wrapper-114 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-115 {
  color: #ffffffb2;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-right: -211px;
  position: relative;
  width: 573px;
}

.element .frame-68 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 203px;
}

.element .text-wrapper-116 {
  align-self: stretch;
  color: #ffffffb2;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.element .text-wrapper-117 {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  position: relative;
  text-align: right;
}

.element .frame-69 {
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.element .button-13 {
  position: relative;
}

.element .button-13:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .overlap-26 {
  left: 20px;
  position: absolute;
}

.element .view-31 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .text-wrapper-118 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 42px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
}

.element .overlap-27 {
  height: 87px;
  left: 0;
  position: absolute;
  top: 0;
  width: 320px;
}

.element .text-wrapper-119 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  top: 0;
  width: 320px;
}

.element .pattern-8 {
  background-image: url(../../../static/img/union-21.svg);
  background-size: 100% 100%;
  height: 68px;
  left: 239px;
  position: absolute;
  top: 13px;
  width: 80px;
}

.element .button-14 {
  position: absolute;
}

.element .button-14:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .pattern-9 {
  position: absolute;
}

.element .overlap-group-20 {
  position: relative;
}

.element .polygon-12 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .polygon-13 {
  position: absolute;
}

.element .polygon-14 {
  left: 0;
  position: absolute;
}

.element .vector-24 {
  position: absolute;
  top: 1px;
  width: 1px;
}

.element .vector-25 {
  position: absolute;
}

.element .vector-26 {
  position: absolute;
}

.element .group-17 {
  height: 153px;
  left: 0;
  position: absolute;
  top: 929px;
  width: 322px;
}

.element .text-wrapper-120 {
  color: var(--white);
  font-family: "DM Sans", Helvetica;
  font-size: 17px;
  font-style: italic;
  font-weight: 400;
  left: 34px;
  letter-spacing: 0;
  line-height: 20.4px;
  position: absolute;
  top: 13px;
  width: 286px;
}

.element .quote-8 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 24px;
}

.element .union-19 {
  height: 18px;
  left: 13px;
  position: absolute;
  top: 0;
  width: 11px;
}

.element .union-20 {
  height: 18px;
  left: 0;
  position: absolute;
  top: 0;
  width: 11px;
}

.element .view-32 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 10px;
  padding: 20px;
  position: absolute;
  top: 646px;
  width: 309px;
}

.element .text-wrapper-121 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .star-22 {
  height: 15.21px;
  position: relative;
  width: 15.6px;
}

.element .text-wrapper-122 {
  align-self: stretch;
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  position: relative;
}

.element .view-33 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  padding: 20px;
  position: absolute;
  top: 107px;
  width: 310px;
}

.element .view-34 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  padding: 20px;
  position: absolute;
  top: 472px;
  width: 310px;
}

.element .view-35 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  left: 10px;
  padding: 20px;
  position: absolute;
  top: 281px;
  width: 310px;
}

.element .text-wrapper-123 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  width: 198px;
}

.element .text-wrapper-124 {
  color: var(--white);
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 947px;
  width: 580px;
}

.element .quote-9 {
  height: 23px;
  left: 13px;
  position: absolute;
  top: 929px;
  width: 31px;
}

.element .union-21 {
  height: 23px;
  left: 17px;
  position: absolute;
  top: 0;
  width: 14px;
}

.element .union-22 {
  height: 23px;
  left: 0;
  position: absolute;
  top: 0;
  width: 14px;
}

.element .view-36 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 373px;
  padding: 40px;
  position: absolute;
  top: 618px;
  width: 332px;
}

.element .text-wrapper-125 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.element .text-wrapper-126 {
  align-self: stretch;
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  position: relative;
}

.element .view-37 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  padding: 40px;
  position: absolute;
  top: 130px;
  width: 417px;
}

.element .text-wrapper-127 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .view-38 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 0;
  padding: 40px;
  position: absolute;
  top: 399px;
  width: 353px;
}

.element .text-wrapper-128 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: 203px;
}

.element .view-39 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--white);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 437px;
  padding: 40px;
  position: absolute;
  top: 273px;
  width: 291px;
}

.element .text-wrapper-129 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: 198px;
}

.element .star-23 {
  height: 22.81px;
  margin-left: -10.7px;
  position: relative;
  width: 23.4px;
}

.element .pattern-10 {
  background-image: url(../../../static/img/union-53.svg);
  background-size: 100% 100%;
  height: 106px;
  left: 540px;
  position: absolute;
  top: 137px;
  width: 124px;
}

.element .vector-27 {
  position: absolute;
}

.element .view-40 {
  left: 20px;
  position: absolute;
}

.element .text-wrapper-130 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.element .view-41 {
  left: 0;
  position: absolute;
}

.element .frame-70 {
  border: 1px solid;
  border-color: transparent;
  border-image: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0) 48.59%, rgb(255, 255, 255) 100%) 1;
  border-radius: 4px;
  display: flex;
  left: 0;
  position: absolute;
  top: 0;
}

.element .star-24 {
  position: relative;
}

.element .IFS-EMDR-2 {
  color: #ffffff;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .view-42 {
  left: 0;
  position: absolute;
}

.element .frame-71 {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 728px;
}

.element .text-wrapper-131 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: 200px;
}

.element .line-20 {
  height: 7.36px;
  object-fit: cover;
  position: relative;
  width: 30.5px;
}

.element .frame-72 {
  height: 39.2px;
  position: relative;
  width: 40px;
}

.element .rectangle-56 {
  height: 39px;
  left: 0;
  position: absolute;
  top: 0;
  width: 32px;
}

.element .group-18 {
  height: 29px;
  left: 26px;
  position: absolute;
  top: -1px;
  transform: rotate(45.61deg);
  width: 5px;
}

.element .rectangle-57 {
  border: 1.7px solid;
  border-color: var(--white);
  height: 17px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 5px;
}

.element .rectangle-58 {
  border: 1.7px solid;
  border-color: var(--white);
  border-radius: 2px 2px 0px 0px;
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
}

.element .vector-28 {
  height: 9px;
  left: 16px;
  position: absolute;
  top: 25px;
  width: 9px;
}

.element .text-wrapper-132 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  width: 198px;
}

.element .ecker-update {
  left: 0;
  position: absolute;
}

.element .img-4 {
  height: 24px;
  position: relative;
  width: 24px;
}

.element .text-wrapper-133 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .vector-29 {
  height: 20px;
  left: 10px;
  position: absolute;
  top: 41px;
  width: 17px;
}

.element .div-18 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 40px;
  position: absolute;
  top: 44px;
}

.element .overlap-group-21 {
  height: 24px;
  position: relative;
}

.element .rectangle-59 {
  height: 24px;
  left: 0;
  position: absolute;
  top: 0;
  width: 19px;
}

.element .vector-30 {
  height: 12px;
  left: 3px;
  position: absolute;
  top: 4px;
  width: 12px;
}

.element .group-19 {
  height: 17px;
  left: 15px;
  position: absolute;
  top: -1px;
  transform: rotate(45.61deg);
  width: 3px;
}

.element .vector-31 {
  height: 3px;
  left: 0;
  position: absolute;
  top: 13px;
  transform: rotate(-45.61deg);
  width: 3px;
}

.element .rectangle-60 {
  height: 9px;
  left: -3px;
  position: absolute;
  top: 4px;
  transform: rotate(-45.61deg);
  width: 9px;
}

.element .rectangle-61 {
  border: 1px solid;
  border-color: var(--white);
  border-radius: 2px 2px 0px 0px;
  height: 3px;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(0.58deg);
  width: 3px;
}

.element .vector-32 {
  height: 6px;
  left: 9px;
  position: absolute;
  top: 15px;
  width: 6px;
}

.element .vector-33 {
  height: 20px;
  left: 48px;
  position: absolute;
  top: 88px;
  width: 17px;
}

.element .div-19 {
  align-items: center;
  display: inline-flex;
  gap: 16px;
  left: 80px;
  position: absolute;
  top: 88px;
}

.element .view-43 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
}

.element .view-44 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
}

.element .text-wrapper-134 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .frame-73 {
  position: relative;
  z-index: 10000;
}

.element .flexcontainer-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.element .text-11 {
  align-self: stretch;
  color: var(--white);
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .text-wrapper-135 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}

.element .text-12 {
  align-self: stretch;
  color: var(--white);
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .span-4 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}

.element .text-13 {
  align-self: stretch;
  color: var(--white);
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .text-wrapper-136 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}

.element .frame-74 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.element .frame-75 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.element .text-wrapper-137 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .text-wrapper-138 {
  align-self: stretch;
  color: #ffffffb2;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .frame-76 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}

.element .text-wrapper-139 {
  align-self: stretch;
  color: #ffffffb2;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.element .clin-soc-work-j-2 {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  text-align: right;
}

.element .class-12 {
  flex: 0 0 auto !important;
  padding: 16px 20px !important;
  width: 320px !important;
}

.element .overlap-28 {
  position: absolute;
}

.element .view-45 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .union-23 {
  left: 0;
  position: absolute;
}

.element .vector-34 {
  height: 502px;
  left: 0;
  position: absolute;
  top: 55px;
  width: 768px;
}

.element .text-wrapper-140 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 42px;
  font-weight: 500;
  left: 17px;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
}

.element .tarif-5 {
  left: 17px;
  position: absolute;
}

.element .overlap-29 {
  position: relative;
}

.element .button-15 {
  position: absolute;
  z-index: 10000;
}

.element .button-15:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .content-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: absolute;
}

.element .div-20 {
  align-self: stretch;
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.element .star-25 {
  position: relative;
}

.element .div-21 {
  color: #ffffff80;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .star-26 {
  position: relative;
}

.element .div-22 {
  color: #ffffff80;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .star-27 {
  height: 10px;
  position: relative;
  width: 10px;
}

.element .text-wrapper-141 {
  color: #ffffff80;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  margin-top: -1px;
  position: relative;
}

.element .div-23 {
  color: var(--white);
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
  width: 292px;
}

.element .arc-7 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .overlap-group-22 {
  position: relative;
}

.element .rectangle-62 {
  position: absolute;
  top: 3px;
}

.element .rectangle-63 {
  position: absolute;
  top: 3px;
}

.element .line-21 {
  height: 1px;
  object-fit: cover;
  position: absolute;
}

.element .ellipse-37 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  position: absolute;
  top: 0;
  width: 6px;
}

.element .ellipse-38 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 0;
  position: absolute;
  width: 6px;
}

.element .ellipse-39 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  position: absolute;
  width: 6px;
}

.element .eye-6 {
  height: 39px;
  position: absolute;
  width: 80px;
}

.element .group-20 {
  position: absolute;
}

.element .text-wrapper-142 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 17px;
  font-style: italic;
  font-weight: 400;
  left: 34px;
  letter-spacing: 0;
  line-height: 20.4px;
  position: absolute;
  top: 13px;
  width: 286px;
}

.element .overlap-group-23 {
  height: 585px;
  position: relative;
}

.element .button-16 {
  height: 64px;
  left: 21px;
  position: absolute;
  top: 488px;
  width: 311px;
  z-index: 10000;
}

.element .button-16:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .content-7 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 22px;
  position: absolute;
  top: 102px;
  width: 310px;
}

.element .text-wrapper-143 {
  align-self: stretch;
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 26.4px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.element .arc-8 {
  height: 585px;
  left: 0;
  position: absolute;
  top: 0;
  width: 357px;
}

.element .rectangle-64 {
  height: 579px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 177px;
}

.element .rectangle-65 {
  height: 579px;
  left: 178px;
  position: absolute;
  top: 3px;
  width: 177px;
}

.element .line-22 {
  height: 1px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 581px;
  width: 352px;
}

.element .ellipse-40 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 175px;
  position: absolute;
  top: 0;
  width: 6px;
}

.element .ellipse-41 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 0;
  position: absolute;
  top: 579px;
  width: 6px;
}

.element .ellipse-42 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 351px;
  position: absolute;
  top: 579px;
  width: 6px;
}

.element .eye-7 {
  height: 39px;
  left: 138px;
  position: absolute;
  top: 43px;
  width: 80px;
}

.element .group-21 {
  height: 99px;
  left: 403px;
  position: absolute;
  top: 753px;
  width: 350px;
}

.element .text-wrapper-144 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 20px;
  font-style: italic;
  font-weight: 400;
  left: 64px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  top: 27px;
  width: 284px;
}

.element .overlap-30 {
  height: 638px;
  left: 31px;
  position: absolute;
  top: 34px;
  width: 360px;
}

.element .vector-35 {
  height: 218px;
  left: 0;
  position: absolute;
  top: 0;
  width: 360px;
}

.element .text-wrapper-145 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 17px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  top: 1px;
}

.element .tarif-6 {
  height: 541px;
  left: 17px;
  position: absolute;
  top: 97px;
  width: 326px;
}

.element .overlap-group-24 {
  height: 541px;
  position: relative;
}

.element .button-17 {
  height: 54px;
  left: 23px;
  position: absolute;
  top: 454px;
  width: 280px;
}

.element .button-17:hover {
  border-radius: 100;
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.6);  
}

.element .content-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 23px;
  position: absolute;
  top: 92px;
  width: 282px;
}

.element .text-wrapper-146 {
  align-self: stretch;
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.element .text-wrapper-147 {
  color: var(--white);
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  margin-right: -10px;
  position: relative;
  width: 292px;
}

.element .arc-9 {
  height: 541px;
  left: 0;
  position: absolute;
  top: 0;
  width: 326px;
}

.element .rectangle-66 {
  height: 535px;
  left: 2px;
  position: absolute;
  top: 3px;
  width: 163px;
}

.element .rectangle-67 {
  height: 535px;
  left: 165px;
  position: absolute;
  top: 3px;
  width: 159px;
}

.element .line-23 {
  height: 1px;
  left: 7px;
  object-fit: cover;
  position: absolute;
  top: 537px;
  width: 316px;
}

.element .ellipse-43 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 160px;
  position: absolute;
  top: 0;
  width: 6px;
}

.element .ellipse-44 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 0;
  position: absolute;
  top: 535px;
  width: 6px;
}

.element .ellipse-45 {
  background-color: var(--white);
  border-radius: 3px;
  height: 6px;
  left: 320px;
  position: absolute;
  top: 535px;
  width: 6px;
}

.element .eye-8 {
  height: 39px;
  left: 123px;
  position: absolute;
  top: 33px;
  width: 80px;
}

.element .pattern-11 {
  position: absolute;
}

.element .union-24 {
  height: 150px;
  left: 1px;
  position: absolute;
  top: -10px;
  transform: rotate(15deg);
  width: 76px;
}

.element .text-14 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  position: absolute;
  top: 309px;
  width: 320px;
}

.element .frame-77 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  position: relative;
  width: 100%;
}

.element .text-wrapper-148 {
  color: #ffffff;
  flex: 1;
  font-family: "DM Sans", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.8px;
  margin-top: -1px;
  position: relative;
}

.element .video-4 {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 183px;
  left: 0;
  position: absolute;
  top: 107px;
  width: 320px;
}

.element .group-22 {
  background-color: var(--variable-collection-bg);
  border-radius: 28px;
  height: 56px;
  left: 132px;
  position: relative;
  top: 64px;
  width: 56px;
}

.element .polygon-15 {
  height: 16px;
  left: 22px;
  position: absolute;
  top: 20px;
  width: 15px;
}

.element .text-wrapper-149 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 24px;
  font-weight: 500;
  left: 2px;
  letter-spacing: 0;
  line-height: 28.8px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .text-wrapper-150 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 16px;
  font-weight: 600;
  left: 3px;
  letter-spacing: 0;
  line-height: 19.2px;
  position: absolute;
  top: 49px;
  width: 315px;
}

.element .view-46 {
  position: absolute;
}

.element .text-15 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  position: absolute;
  top: 579px;
  width: 728px;
}

.element .video-5 {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 413px;
  left: 0;
  position: absolute;
  top: 136px;
  width: 728px;
}

.element .group-23 {
  background-color: var(--variable-collection-bg);
  border-radius: 36px;
  height: 72px;
  left: 328px;
  position: relative;
  top: 171px;
  width: 72px;
}

.element .text-wrapper-151 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 42px;
  font-weight: 500;
  left: 2px;
  letter-spacing: 0;
  line-height: 50.4px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.element .text-wrapper-152 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-size: 22px;
  font-weight: 600;
  left: 3px;
  letter-spacing: 0;
  line-height: 26.4px;
  position: absolute;
  top: 80px;
  white-space: nowrap;
}

.element .union-25 {
  height: 101px;
  left: 1px;
  position: absolute;
  top: -9px;
  transform: rotate(15deg);
  width: 31px;
}

.element .view-47 {
  height: 552px;
  position: absolute;
}

.element .text-wrapper-153 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.element .view-48 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
}

.element .frame-78 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .text-wrapper-154 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .text-wrapper-155 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.element .frame-79 {
  height: 13px;
  position: relative;
  width: 18px;
}

.element .hierarchy-tertiary-state-default {
  padding: 4px 10px !important;
}

.element .flexcontainer-8 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.element .text-16 {
  align-self: stretch;
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .text-wrapper-156 {
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}

.element .text-17 {
  align-self: stretch;
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .text-wrapper-157 {
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}

.element .frame-80 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .text-wrapper-158 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .flexcontainer-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.element .text-18 {
  align-self: stretch;
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .text-wrapper-159 {
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}

.element .text-19 {
  align-self: stretch;
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .text-wrapper-160 {
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
}

.element .frame-81 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.element .text-wrapper-161 {
  color: var(--white);
  font-family: "Lora", Helvetica;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
}

.element .hierarchy-tertiary-instance {
  flex: 0 0 auto !important;
  margin-left: -47px !important;
}

.element .text-wrapper-162 {
  align-self: stretch;
  color: #ffffff80;
  font-family: "DM Sans", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;
}

.element .view-49 {
  left: 22px;
  position: absolute;
}

.element .view-50 {
  left: 0;
  position: absolute;
  top: 0;
}

.element .text-wrapper-163 {
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-weight: 500;
  left: 0;
  letter-spacing: 0;
  position: absolute;
  top: 0;
}

.element .btns-3 {
  display: flex;
  left: 0;
  position: absolute;
  justify-content: space-evenly;
}

.element .class-13 {
  font-family: "Lora", Helvetica !important;
  font-size: 18px !important;
  font-style: unset !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 21.6px !important;
  margin-top: unset !important;
}

.element .class-14 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  padding: 16px 20px 16px 32px !important;
  width: 100% !important;
}

.element .class-15 {
  flex: 1 !important;
  flex-grow: 1 !important;
  width: unset !important;
}
