.menu-icon {
  background-color: #ffffff0d;
  border-radius: 4px;
  height: 67px;
  overflow: hidden;
  position: relative;
  width: 67px;
  visibility: hidden;
}

.menu-icon .rectangle {
  background-color: var(--white);
  border-radius: 10px;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 23px;
  width: 35px;
}

.menu-icon .div {
  background-color: var(--white);
  border-radius: 10px;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 33px;
  width: 35px;
}

.menu-icon .rectangle-2 {
  background-color: var(--white);
  border-radius: 10px;
  height: 2px;
  left: 16px;
  position: absolute;
  top: 43px;
  width: 35px;
}

.menu-icon .union {
  height: 25px;
  left: 21px;
  position: absolute;
  top: 22px;
  width: 25px;
}
