.header {
  -webkit-backdrop-filter: blur(34px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(34px) brightness(100%);
  background-color: #ffffff1a;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 24px 40px;
  position: relative;
  width: 1280px;
}

.header .tabs {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 50px;
  justify-content: center;
  position: relative;
}

.header .text-wrapper-13 {
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21.6px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
  cursor: pointer;
}
