.education-cell {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 320px;
}

.education-cell .frame-2 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.education-cell .frame-3 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 180px;
}

.education-cell .text-wrapper-2 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
}

.education-cell .text-wrapper-3 {
  align-self: stretch;
  color: #ffffffb2;
  font-family: "DM Sans", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
}

.education-cell .frame-4 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 140px;
}

.education-cell .element-2 {
  align-self: stretch;
  color: #ffffffb2;
  font-family: "DM Sans", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.education-cell .text-wrapper-4 {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 15px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  position: relative;
  text-align: right;
}

.education-cell .line {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.5px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  object-fit: cover;
  position: relative;
  width: 100%;
}
