.education-cell-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
  width: 440px;
}

.education-cell-wrapper .frame-5 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 264px;
}

.education-cell-wrapper .frame-6 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 263px;
}

.education-cell-wrapper .text-wrapper-6 {
  align-self: stretch;
  color: #ffffff;
  font-family: "Lora", Helvetica;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}

.education-cell-wrapper .text-wrapper-7 {
  align-self: stretch;
  color: #ffffffb2;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  position: relative;
}

.education-cell-wrapper .frame-7 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: -175px;
  position: relative;
  width: 176px;
}

.education-cell-wrapper .element-4 {
  align-self: stretch;
  color: #ffffffb2;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.education-cell-wrapper .text-wrapper-8 {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 16px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 19.2px;
  position: relative;
  text-align: right;
}

.education-cell-wrapper .line-4 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.5px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  object-fit: cover;
  position: relative;
  width: 100%;
}
