.hierarchy-secondary {
  align-items: center;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 36px;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 28px;
  position: relative;
  width: 412px;
  transition: box-shadow 0.2s ease-in-out;
}

.hierarchy-secondary .frame {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 14px;
  padding: 0px 10px;
  position: relative;
}

.hierarchy-secondary .text-wrapper {
  color: #ffffff;
  font-family: var(--1280-button-text-2-font-family);
  font-size: var(--1280-button-text-2-font-size);
  font-style: var(--1280-button-text-2-font-style);
  font-weight: var(--1280-button-text-2-font-weight);
  letter-spacing: var(--1280-button-text-2-letter-spacing);
  line-height: var(--1280-button-text-2-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.hierarchy-secondary .arrow-instance {
  position: relative !important;
}

.hierarchy-secondary:hover {
  box-shadow: 0 0 15px 0 rgba(255, 255, 255, 0.8);
}