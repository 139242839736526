.bullet {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 1180px;
}

.bullet .frame-8 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.bullet .frame-9 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 362px;
}

.bullet .text-wrapper-9 {
  color: #ffffff;
  font-family: var(--1280-h4-font-family);
  font-size: var(--1280-h4-font-size);
  font-style: var(--1280-h4-font-style);
  font-weight: var(--1280-h4-font-weight);
  letter-spacing: var(--1280-h4-letter-spacing);
  line-height: var(--1280-h4-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.bullet .text-wrapper-10 {
  color: #ffffffb2;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-right: -517px;
  position: relative;
  width: 879px;
}

.bullet .frame-10 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
  width: 203px;
}

.bullet .element-5 {
  align-self: stretch;
  color: #ffffffb2;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  margin-top: -1px;
  position: relative;
  text-align: right;
}

.bullet .text-wrapper-11 {
  align-self: stretch;
  color: #ffffff;
  font-family: "DM Sans", Helvetica;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21.6px;
  position: relative;
  text-align: right;
}

.bullet .line-5 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -0.5px;
  margin-left: -0.5px;
  margin-right: -0.5px;
  object-fit: cover;
  position: relative;
  width: 100%;
}
