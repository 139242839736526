.factoid {
  height: 136px;
  width: 380px;
}

.factoid .overlap-group-2 {
  border-radius: 4px;
  height: 136px;
  position: relative;
}

.factoid .text-wrapper-5 {
  color: #ffffff;
  font-family: var(--1280-text-font-family);
  font-size: var(--1280-text-font-size);
  font-style: var(--1280-text-font-style);
  font-weight: var(--1280-text-font-weight);
  left: 305px;
  letter-spacing: var(--1280-text-letter-spacing);
  line-height: var(--1280-text-line-height);
  position: absolute;
  text-align: right;
  top: 91px;
  white-space: nowrap;
}

.factoid .rectangle-3 {
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 4px;
  height: 136px;
  left: 0;
  position: absolute;
  top: 0;
  width: 380px;
}

.factoid .star {
  height: 39px;
  left: 24px;
  position: absolute;
  top: 24px;
  width: 39px;
}

.factoid .element-3 {
  color: #ffffff;
  font-family: var(--1280-h1-large-font-family);
  font-size: var(--1280-h1-large-font-size);
  font-style: var(--1280-h1-large-font-style);
  font-weight: var(--1280-h1-large-font-weight);
  left: 190px;
  letter-spacing: var(--1280-h1-large-letter-spacing);
  line-height: var(--1280-h1-large-line-height);
  position: absolute;
  text-align: right;
  top: 19px;
  white-space: nowrap;
}
